import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
export default {
  __name: 'CasesFooterMain',
  setup: function setup(__props) {
    var $route = useRoute();
    var routesToHide = ['my-feed', 'learning-my', 'learning-course-course-module-module-test'];
    var hide = computed(function () {
      var routeName = $route.name;
      if (!routeName) return false;
      var testRoute = function testRoute(r) {
        return new RegExp("".concat(r, "___")).test(routeName);
      };
      return routesToHide.some(testRoute);
    });
    function toggleExpand(id) {
      var section = document.getElementById(id);
      var expand = section.querySelector('.cf-collapse');
      var list = expand.querySelector('.cf-list');
      var height = list.offsetHeight;
      if (expand.offsetHeight == 0) {
        expand.style.height = "".concat(height, "px");
        expand.style.opacity = 1;
        section.classList.add("expanded");
      } else {
        expand.style.height = '0px';
        expand.style.opacity = 0;
        section.classList.remove("expanded");
      }
    }
    var minimizeMenu = computed(function () {
      var name = $route.name;
      if (!name) return false;
      return [/^learning-course-course/, [/^learning-course-slug/, 'slug']].some(function (params) {
        var regex = Array.isArray(params) ? params[0] : params;
        var paramKey = Array.isArray(params) ? params[1] || 'course' : 'course';
        if (!regex.test(name)) return false;
        return paramKey in ($route === null || $route === void 0 ? void 0 : $route.params) && $route.params[paramKey] == 'creativity-beyond-borders';
      });
    });
    return {
      __sfc: true,
      $route: $route,
      routesToHide: routesToHide,
      hide: hide,
      toggleExpand: toggleExpand,
      minimizeMenu: minimizeMenu
    };
  }
};