import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import DdButton from '~/components/DdButton.vue';
import PanelButton from '~/components/panels/PanelButton.vue';
export default {
  name: 'CasesMenu',
  components: {
    DdButton: DdButton,
    PanelButton: PanelButton,
    WebpPicture: function WebpPicture() {
      return import('~/components/WebpPicture.vue');
    },
    Dropdown: function Dropdown() {
      return import('~/components/CasesDropdown.vue');
    },
    Checkbox: function Checkbox() {
      return import('~/components/form/Checkbox.vue');
    },
    AcceptModer: function AcceptModer() {
      return import('~/components/cases/AcceptModer.vue');
    },
    ProfilePanel: function ProfilePanel() {
      return import('~/components/panels/ProfilePanel.vue');
    },
    LanguageSettings: function LanguageSettings() {
      return import('~/components/dropdowns/LanguageSettings.vue');
    },
    HelpRoot: function HelpRoot() {
      return import('~/components/dropdowns/HelpRoot.vue');
    },
    HelpCases: function HelpCases() {
      return import('~/components/dropdowns/HelpCases.vue');
    },
    UserMenu: function UserMenu() {
      return import('~/components/dropdowns/UserMenu.vue');
    },
    AdminMenu: function AdminMenu() {
      return import('~/components/dropdowns/AdminMenu.vue');
    },
    CartsMenu: function CartsMenu() {
      return import('~/components/dropdowns/CartsMenu.vue');
    }
  },
  computed: {
    isDesctop: function isDesctop() {
      return ['lg', 'xl', 'xxl'].includes(this.$mq);
    },
    navigationButton: function navigationButton() {
      return this.isDesctop ? 'nuxt-link' : 'DdButton';
    },
    notificationsStore: function notificationsStore() {
      return useNotificationsStore();
    },
    locale: function locale() {
      return this.$i18n.locale;
    },
    cartStore: function cartStore() {
      return useCartStore();
    },
    cartEmpty: function cartEmpty() {
      return this.cartStore.isEmpty;
    },
    appStore: function appStore() {
      return useAppStore();
    },
    subscriptionStore: function subscriptionStore() {
      return useSubscriptionStore();
    },
    trialAvailable: function trialAvailable() {
      return this.subscriptionStore.trialVisible;
    },
    userHasExclamation: function userHasExclamation() {
      if (!this.$auth.user && !this.$auth.user.moders) return false;
      return this.$auth.user.moders.some(function (o) {
        return !o.approved;
      });
    },
    userBadgeCounter: function userBadgeCounter() {
      return this.cartStore.itemsLength + this.notificationsStore.unreadCount;
    },
    saleStore: function saleStore() {
      return useSaleStore();
    },
    isReviewer: function isReviewer() {
      var role = this.$auth.user && this.$auth.user.role;
      var roles = ['Reviewer'];
      return role && roles.includes(role.name.en);
    },
    isModer: function isModer() {
      var role = this.$auth.user && this.$auth.user.role;
      var roles = ['Administrator', 'Creative Practice Administrator', 'Creative Practice TeamLead'];
      return role && roles.includes(role.name.en);
    }
  },
  watch: {
    locale: function locale(newVal) {
      this.$gtag('set', 'user_properties', {
        interface_locale: newVal
      });
    }
  },
  mounted: function mounted() {
    this.$gtag('set', 'user_properties', {
      color_mode: this.defineUserTheme(),
      interface_locale: this.locale
    });
    this.$socket.on('connect', this.onOnline);
  },
  methods: {
    onOnline: function onOnline() {
      // Rehydrate notifications
      if (this.notificationsStore.hydrated) {
        this.notificationsStore.hydrate();
      }

      // Check for new messages
      this.notificationsStore.checkUnread();
    },
    openSubDd: function openSubDd(payload) {
      var _this = this;
      this.$modalityStore.dropdowns.forEach(function (dd) {
        if (dd.name !== payload.name) {
          _this.$modalityStore.closeDropdown({
            name: dd.name
          });
        }
      });
      this.$modalityStore.openDropdown(payload);
    },
    toggleCart: function toggleCart(index) {
      this.cartStore.setActiveCartIndex(index);
      this.$modalityStore.toggleModal({
        type: 'cart',
        name: 'def'
      });
    },
    setTheme: function setTheme(val) {
      this.$cookieStore.setState("theme", val);
      this.$gtag('set', 'user_properties', {
        color_mode: this.defineUserTheme()
      });
    },
    scrollTop: function scrollTop(path) {
      if (this.$route.path.match('/my/feed') && path.match('/my/feed')) this.$scrollTo('body');
    },
    defineUserTheme: function defineUserTheme() {
      var theme = this.$cookieStore.theme;
      var GA_mode = 'Light';
      if (!['light', 'dark'].includes(theme)) {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          theme = 'dark';
        } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
          theme = 'light';
        }
      }
      if (theme == 'light') GA_mode = 'Light';
      if (theme == 'dark') GA_mode = 'Dark';
      this.appStore.$patch({
        colorMode: theme
      });
      return GA_mode;
    }
  },
  beforeDestroy: function beforeDestroy() {
    this.$socket.off('connect', this.onOnline);
  }
};