import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.timers.js";
import Cookie from '~/components/Cookie.vue';
import StatusBar from '~/components/navigation/StatusBar.vue';
import CasesFooter from '~/components/CasesFooterMain.vue';
import CasesNavigationMenu from '~/components/navigation/CasesTopMenu.vue';
export default {
  name: 'CasesContent',
  components: {
    Cookie: Cookie,
    StatusBar: StatusBar,
    CasesFooter: CasesFooter,
    CasesNavigationMenu: CasesNavigationMenu,
    Hint: function Hint() {
      return import('~/components/hints/Hint.vue');
    },
    Gains: function Gains() {
      return import('~/components/Gains.vue');
    },
    UsersList: function UsersList() {
      return import('~/components/panels/UsersList.vue');
    },
    DrawerTint: function DrawerTint() {
      return import('~/components/DrawerTint.vue');
    },
    CasesPanel: function CasesPanel() {
      return import('~/components/panels/CasesPanel.vue');
    },
    DropdownTint: function DropdownTint() {
      return import('~/components/DropdownTint.vue');
    },
    PullToRefresh: function PullToRefresh() {
      return import('~/components/PullToRefresh.vue');
    },
    CookieSettings: function CookieSettings() {
      return import('~/components/panels/CookieSettings');
    },
    ProfileInformer: function ProfileInformer() {
      return import('~/components/profile/Informer.vue');
    },
    Cart: function Cart() {
      return import('~/components/cart/Cart.vue');
    },
    AddMenu: function AddMenu() {
      return import('~/components/dropdowns/AddMenu.vue');
    },
    AuthPanel: function AuthPanel() {
      return import('~/components/panels/Auth.vue');
    },
    TrialPanel: function TrialPanel() {
      return import('~/components/panels/Trial.vue');
    },
    Subscription: function Subscription() {
      return import('~/components/cart/Subscription.vue');
    },
    NotificationPanel: function NotificationPanel() {
      return import('~/components/notifications/Notifications.vue');
    },
    Sale: function Sale() {
      return import('~/components/banners/Sale.vue');
    },
    Banner: function Banner() {
      return import('~/components/banners/Trial.vue');
    },
    Incubator: function Incubator() {
      return import('~/components/banners/Incubator.vue');
    },
    PriceChange: function PriceChange() {
      return import('~/components/banners/PriceChange.vue');
    }
  },
  data: function data() {
    return {
      saleStart: new Date('2024-09-24T21:00:00.000Z'),
      // saleStart: false,
      saleEnd: new Date('2024-10-07T20:59:59.000Z'),
      incubatorEnd: new Date('2024-05-31T20:59:59.000Z'),
      showBanner: false,
      showSale: false,
      showIncubator: false,
      showPriceChange: false
    };
  },
  computed: {
    usersListStore: function usersListStore() {
      return useUsersListStore();
    },
    subscriptionStore: function subscriptionStore() {
      return useSubscriptionStore();
    },
    hasSubscription: function hasSubscription() {
      return this.subscriptionStore.token;
    },
    trialAvailable: function trialAvailable() {
      return this.subscriptionStore.trialVisible;
    },
    cookieConsentVisible: function cookieConsentVisible() {
      return this.$cookieStore.cookieConsentVisible;
    },
    isStandalone: function isStandalone() {
      if (process.server || !window) return false;
      var isIOSStandalone = window.navigator.standalone;
      var isOtherStandalone = window.matchMedia('(display-mode: standalone)').matches;
      return isIOSStandalone || isOtherStandalone;
    },
    isTouchDevice: function isTouchDevice() {
      if (process.server || !window) return false;
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }
  },
  watch: {
    // Show banner in 10 sec after cookie consent closed
    cookieConsentVisible: function cookieConsentVisible(val, oldVal) {
      var _this = this;
      if (oldVal && !val && process.browser) {
        setTimeout(function () {
          return _this.pickBanner();
        }, 10000);
      }
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    // Show banner in 5 sec if cookie consent already accespted
    if (!this.cookieConsentVisible) {
      setTimeout(function () {
        return _this2.pickBanner();
      }, 5000);
    }
  },
  methods: {
    pickBanner: function pickBanner() {
      var now = new Date();
      var priceChangeEnd = new Date('2024-02-29T22:00:00.000Z');
      var saleStarted = !this.saleStart || now >= this.saleStart;
      var saleEnded = now >= this.saleEnd;
      var priceChanged = now >= priceChangeEnd;
      var incubatorEnded = now >= this.incubatorEnd;
      if (saleStarted && !saleEnded && this.$cookieStore.isUkrainian) {
        this.showSale = true;
      } else if (!incubatorEnded) {
        this.showIncubator = true;
      } else if (!this.hasSubscription && this.trialAvailable) {
        this.showBanner = true;
      } else if (!priceChanged) {
        this.showPriceChange = true;
      }
    }
  }
};