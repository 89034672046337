<i18n>
{
    "uk": {
        "heading": "Ми використовуємо кукі. Ви не проти?",
        "text": "Вітаємо! 👋 Перш ніж ви продовжите користуватись нашою платформою, маємо отримати від вас згоду на користування файлами кукі. Це такі невеличкі файли, що полегшують користування вебсайтами, записуючи дані щодо вашої взаємодії з ними. Вони потрібні для входу в систему, збору статистики та поліпшення досвідом користування. Докладніше про них ми розповідаємо в",
        "link": "Повідомленні про використання файлів cookie",
        "all": "Прийняти всі",
        "necessary": "Прийняти необхідні",
        "settings": "Налаштувати",
        "cookieImageAlt": "Кіт сидить на кріслі та їсть печиво"
    },
    "en": {
        "heading": "We use cookies. Do you mind?",
        "text": "Hi there! 👋 Before you proceed, we need your consent to use cookies. Cookies are tiny files that help improve your website experience by remembering your interactions. They're essential for logging in, gathering statistics, and enhancing usability. Learn more about them in our",
        "link": "Cookie notice",
        "all": "Accept All",
        "necessary": "Accept Necessary",
        "settings": "Customize",
        "cookieImageAlt": "A cat sitting on a chair and eating a cookie"
    }
}
</i18n>

<template>
<transition name="cookie">
    <div 
        class="cookie-overlay" 
        v-if="!cookieConsent.consent"
    >
        <!--googleoff: all-->
        <div class="cookie-note py-2 py-sm-5 elevate" role="dialog" aria-modal="true">
            <div class="page-container">
                <div class="row">
                    <div class="col-lg-4 d-none d-lg-block">
                        <WebpPicture
                            src="https://cdn.cases.media/image/standart/cookie-cat-3-4-3.jpeg"
                            :alt="$t('cookieImageAlt')"
                            width="1481"
                            height="1123"
                            class="polished"
                            imgClass="block-image w-100 polished"
                            decoding="async"
                        />
                    </div>
                    <div class="col-lg-8">
                        <div class="h4 h3-xl fw-800 m-0">{{ $t('heading') }}</div>

                        <p class="mb-2 mt-1">
                            {{ $t('text') }} 
                            <nuxt-link :to="localePath('/cookie')" class="b-link">{{ $t('link') }}</nuxt-link>.
                        </p>

                        <div class="d-flex flex-column flex-md-row align-items-center">
                            <button 
                                class="btn-primary w-100 w-md-auto" 
                                tabindex="1"
                                @click="setConsent({marketing: true, performance: true})"
                            >
                                {{ $t('all') }}
                            </button>

                            <button 
                                class="btn-secondary w-100 w-md-auto my-2 my-md-0 mx-2 tt-upper fw-600 elevate" 
                                tabindex="2"
                                @click="setConsent({marketing: false, performance: false})"
                            >
                                {{ $t('necessary') }}
                            </button>

                            <PanelButton 
                                tabindex="3"
                                name="cookies-settings"
                                class="btn-link-g fw-600 w-100 w-md-auto"
                            >
                                {{ $t('settings') }}
                            </PanelButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--googleon: all-->
    </div>
</transition>
</template>

<script>
import PanelButton from '~/components/panels/PanelButton.vue';
import WebpPicture from '~/components/WebpPicture.vue';

export default {
    components: {
        PanelButton,
        WebpPicture
    },

    computed: {
        cookieConsent () {
            return this.$cookieStore.getCookieConsent
        }
    },

    methods: {
        setConsent (data) {
            this.$cookieStore.setConsent(data);
        }
    }
}
</script>

<style scoped lang="postcss">
    .cookie-overlay {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 99;
        background-color: rgba(14, 16, 20, 35%);
    }

    .cookie-note {
        position: fixed;
        bottom: calc(48px + env(safe-area-inset-bottom));
        left: 0;
        right: 0;
        background-color: var(--color-control);

        border-radius: 8px 8px 0 0;

        padding-top: 16px;
        padding-bottom: calc(16px + env(safe-area-inset-bottom));

        @media (min-width: 768px) {
            bottom: 0;
            left: var(--menu-width);
        }

        max-height: calc(100dvh - 80px);
        max-height: calc(100vh - 80px);
        overflow: auto;
    }

    .cookie-leave-active {
        transition: background-color .35s ease;

        .cookie-note {
            transition: .35s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
    }

    .cookie-leave-to {
        background-color: transparent;

        .cookie-note {transform: translateY(100%);}
    }
</style>